import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Jurrin Tuls was a Mechanical Engineer that was hired by `}<a parentName="p" {...{
        "href": "/Enzo%20Quint",
        "title": "Enzo Quint"
      }}>{`Enzo Quint`}</a>{` during their experiments in the `}<a parentName="p" {...{
        "href": "/Bathyslab",
        "title": "Bathyslab"
      }}>{`Bathyslab`}</a>{`. `}</p>
    <p>{`He willingly volunteered to take part in one of the experiments, during which Jurrin's `}<a parentName="p" {...{
        "href": "/Thread",
        "title": "Thread"
      }}>{`Thread`}</a>{` was severed, creating an `}<a parentName="p" {...{
        "href": "/Entanglement",
        "title": "Entanglement"
      }}>{`Entanglement`}</a>{`, allowing for `}<a parentName="p" {...{
        "href": "/Etnu",
        "title": "Etnu"
      }}>{`Etnu`}</a>{` and `}<a parentName="p" {...{
        "href": "/Videnis",
        "title": "Videnis"
      }}>{`Videnis`}</a>{` to emerge from `}<a parentName="p" {...{
        "href": "/Altrement",
        "title": "Altrement"
      }}>{`The Dark Between`}</a>{`, destroying much of the lab in the process.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      